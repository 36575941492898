import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
// import InputTextFieldGrid from "./InputTextFieldGrid";
import InputSelectFieldGrid from "./InputSelectFieldGrid";
import CheckboxFieldGrid from "./CheckboxFieldGrid";
import InputDateRange from "./InputDateRange";
import InputTextFieldGrid from "./InputTextFieldGrid";

const FilterBar = ({ filters, handlerFilter, defaultValues, allValue = '', page, limit }) => {
  const { control, reset, setValue } = useForm({ defaultValues });
  const fields = useWatch({ control, defaultValue: defaultValues });
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) return
    handlerFilter({ ...fields, page, limit }, reset)
    // eslint-disable-next-line
  }, [fields, page, limit, isOpen])

  return (
    <Grid container mb={1}>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item container alignItems={"center"} flexDirection={"row"} spacing={1}>
          <Grid item>Filtros</Grid>
          <Grid flex={1} item container flexDirection={"row"} spacing={1} alignItems={"center"}>
            {filters.map(({ name, label, data, type, disabled, all, onSelect = () => { }, multiple, render }) => (
              type === "select" ? (
                <InputSelectFieldGrid
                  key={name}
                  xs={3}
                  control={control}
                  name={name}
                  label={label}
                  data={!!all ? [{ id: allValue, name: all }, ...data] : data}
                  disabled={disabled}
                  displayEmpty={!!all}
                  onSelect={(value) => onSelect(value, setValue)}
                  multiple={multiple}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  render={render}
                />
              ) : type === "date" ? (
                <InputTextFieldGrid
                  key={name}
                  xs={3}
                  name={name}
                  inputType={'date'}
                  control={control}
                  label={label}
                />
              ) : type === "dates" ? (
                <InputDateRange
                  key={name}
                  xs={3}
                  name={name}
                  control={control}
                  label={label}
                  defaultValue={defaultValues[name]}
                />
              ) : type === "checkbox" ? (
                <CheckboxFieldGrid
                  key={name}
                  xs={3}
                  name={name}
                  control={control}
                  label={label}
                />
              ) : null
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterBar;
