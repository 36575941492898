import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import { cleanSession } from "../redux/slices/sessionSlice";
import { LoginScreen, MainScreen } from "../screens";
import { AdminScreen, CreateUserScreen, EditUserScreen, UserScreen } from "../screens/security/user";
import { CreateRoleScreen, EditRoleScreen, RoleScreen } from "../screens/security/role";
import { CreatePermissionScreen, EditPermissionScreen, PermissionScreen } from "../screens/security/permission";
import { PanelBinnacleScreen, PanelPaymentsScreen, PanelScreen, PanelTransactionsScreen } from "../screens/panel";
import { CreateExtraDenominationScreen, CreateLawfulOperatorScreen, CreatePINScreen, CreateSegmentPINScreen, DenominationScreen, EditExtraDenominationScreen, EditLawfulOperatorScreen, EditPINScreen, EditSegmentPINScreen, ExtraDenominationScreen, OperatorScreen, PINScreen, PaymentServiceScreen, ProductCreateDenominationScreen, ProductCreateOperatorScreen, ProductCreateSegmentScreen, ProductEditDenominationScreen, ProductEditOperatorScreen, ProductEditSegmentScreen, ProductLawfulOperatorScreen, ProductScreen, ProductTabScreen, ProductsPINScreen, ProductsPaymentServiceScreen, SegmentScreen, SegmentsPINScreen } from "../screens/admin/product";
import { CreateProviderScreen, EditProviderScreen, ProviderScreen, MonitorProviderTransactionsScreen, HebeCodesScreen, HebeCodesCreateScreen, HebeCodesEditScreen } from "../screens/admin/provider";
import { CredsScreen, CredsAddScreen, CredsEditService, AmountsScreen, CarriersScreen, ErrorCodesScreen, CreateAmountScreen, CreateErrorCodesScreen, EditAmountScreen, EditErrorCodesScreen, CredTAEScreen, CredPDSScreen, CredPDSSegmentScreen, CredsProductTabScreen, CredPDSProductScreen, EditCredPDSProductScreen, CredPINScreen, CredPINSegmentScreen, CredPINProductScreen, EditCredPINProductScreen, CredTAEOperatorsScreen, CredTAESegmentsScreen, CredTAEProductsScreen } from "screens/admin/provider/creds";
import { ConnectorScreen, CreateConnectorScreen, EditConnectorScreen } from "../screens/admin/connector";
import { CreateLadaScreen, EditLadaScreen, LadasScreen } from "../screens/route/ladas";
import { AirTimeSaleScreen, ElectronicPinSaleScreen, SaleTabScreen, ServicePaymentSaleScreen } from "../screens/sale";
import { BalancesScreen, BinnacleScreen, DetailedScreen, ConciliacionScreen, ConciliacionHistoryScreen, ConciliacionDetailScreen } from "../screens/report";
import { CommerceScreen, CreateCommerceScreen, EditCommerceScreen } from "../screens/client/commerce";
import { BagOperationsScreen, BagPDSProductScreen, BagPDSScreen, BagPDSSegmentScreen, BagPINProductScreen, BagPINScreen, BagPINSegmentScreen, BagProductTabScreen, BagTAEScreen, BagScreen, CreateBagScreen, EditBagScreen, BagTAEOperatorScreen, BagTAERegionScreen, BagTAESegmentScreen, BagTAEDenominationScreen } from "../screens/client/bag";
import { CreateSalePointScreen, EditSalePointScreen, SalePointScreen } from "../screens/client/salePoint";
import SimulatorScreen from "../screens/route/simulator/SimuladorScreen";
import TotalReportScreen from "screens/report/TotalReportScreen";
import { CreateGroupScreen, EditGroupScreen, GroupScreen } from "screens/client/group";
import DailyTotalReportScreen from "screens/report/DailyTotalReportScreen";
import CreatePaymentServiceScreen from "screens/admin/product/pds/CreatePaymentServiceScreen";
import EditPaymentServiceScreen from "screens/admin/product/pds/EditPaymentServiceScreen";
import SegmentsPaymentServiceScreen from "screens/admin/product/pds/SegmentsPaymentServiceScreen";
import CreateSegmentPaymentServiceScreen from "screens/admin/product/pds/CreateSegmentPaymentServiceScreen";
import EditSegmentPaymentServiceScreen from "screens/admin/product/pds/EditSegmentPaymentServiceScreen";
import { AlertMonitorScreen, AlertTab, AlertsScreen, CreateAlertScreen, EditAlertScreen } from "screens/admin/alerts";
import AlertTxnsMonitorScreen from "screens/admin/alerts/AlertTxnsMonitorScreen";
import { ServiceMonitorHistorialScreen, ServiceMonitorScreen } from "screens/admin/serviceMonitor";
import { CreateExceptionScreen, EditExceptionScreen, ExceptionScreen, RouteTabScreen, TAERouteScreen, TAETabScreen } from "screens/route/ruteo";
import PDSRouteScreen from "screens/route/ruteo/pds/PDSRouteScreen";
import PINRouteScreen from "screens/route/ruteo/pin/PINRouteScreen";
import EditCredTAEProductScreen from "screens/admin/provider/creds/tae/EditCredTAEProductScreen";
import Graficas from "screens/panel/Graficas";

const RootRoutes = () => {
  const { token } = useSelector(store => store.session)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // dispatch(setLoading(false))
    if (location.search === "?session_expired")
      dispatch(cleanSession())
    else if (!token && !location.pathname.includes('/newpass'))
      navigate("/")
    // eslint-disable-next-line
  }, [token])

  return (
    <Routes>
      <Route path='/newpass' element={<LoginScreen />} />
      <Route path='/aviso' element={<LoginScreen />} />
      <Route path='/' element={token ? <MainScreen /> : <LoginScreen />}>
        <Route path='*' element={<Redirect path='/' />} />
        <Route path='' element={<Redirect path='/' />} />
        <Route path='panel' element={<PanelScreen />}>
          <Route path="transacciones" element={<PanelTransactionsScreen />} />
          <Route path="abonos" element={<PanelPaymentsScreen />} />
          <Route path="bitacora" element={<PanelBinnacleScreen />} />
        </Route>
        <Route path="graficas" element={<Graficas />} />
        <Route path='admin'>
          <Route path='productos' element={<ProductTabScreen />}>
            <Route path="tae">
              <Route index element={<ProductScreen />} />
              <Route path='operadoras' >
                <Route index element={<OperatorScreen />} />
                <Route path='crear' element={<ProductCreateOperatorScreen />} />
                <Route path='editar' element={<ProductEditOperatorScreen />} />
                <Route path='legales'>
                  <Route index element={<ProductLawfulOperatorScreen />} />
                  <Route path='crear' element={<CreateLawfulOperatorScreen />} />
                  <Route path='editar' element={<EditLawfulOperatorScreen />} />
                </Route>
              </Route>
              <Route path='segmentos' >
                <Route index element={<SegmentScreen />} />
                <Route path='crear' element={<ProductCreateSegmentScreen />} />
                <Route path='editar' element={<ProductEditSegmentScreen />} />
              </Route>
              <Route path='denominaciones' >
                <Route index element={<DenominationScreen />} />
                <Route path='crear' element={<ProductCreateDenominationScreen />} />
                <Route path='editar' element={<ProductEditDenominationScreen />} />
                <Route path='extradenominaciones' >
                  <Route index element={<ExtraDenominationScreen />} />
                  <Route path='crear' element={<CreateExtraDenominationScreen />} />
                  <Route path='editar' element={<EditExtraDenominationScreen />} />
                </Route>
              </Route>
            </Route>
            <Route path="pds">
              <Route index element={<PaymentServiceScreen />} />
              <Route path='segmentos' >
                <Route index element={<SegmentsPaymentServiceScreen />} />
                <Route path='crear' element={<CreateSegmentPaymentServiceScreen />} />
                <Route path='editar' element={<EditSegmentPaymentServiceScreen />} />
              </Route>
              <Route path='productos' >
                <Route index element={<ProductsPaymentServiceScreen />} />
                <Route path='crear' element={<CreatePaymentServiceScreen />} />
                <Route path='editar' element={<EditPaymentServiceScreen />} />
              </Route>
            </Route>
            <Route path="pin">
              <Route index element={<PINScreen />} />
              <Route path='segmentos' >
                <Route index element={<SegmentsPINScreen />} />
                <Route path='crear' element={<CreateSegmentPINScreen />} />
                <Route path='editar' element={<EditSegmentPINScreen />} />
              </Route>
              <Route path='productos' >
                <Route index element={<ProductsPINScreen />} />
                <Route path='crear' element={<CreatePINScreen />} />
                <Route path='editar' element={<EditPINScreen />} />
              </Route>
            </Route>
          </Route>
          <Route path='proveedores' >
            <Route index element={<ProviderScreen />} />
            <Route path='crear' element={<CreateProviderScreen />} />
            <Route path='editar' element={<EditProviderScreen />} />
            <Route path='monitor' element={<MonitorProviderTransactionsScreen />} />
            <Route path="carriers" element={<CarriersScreen />} />
            <Route path='creds'>
              <Route index element={<CredsScreen />} />
              <Route path='create' element={<CredsAddScreen />} />
              <Route path='edit' element={<CredsEditService />} />
              <Route path='productos' element={<CredsProductTabScreen />}>
                <Route path="tae">
                  <Route index element={<CredTAEScreen />} />
                  <Route path='operadoras' element={<CredTAEOperatorsScreen />} />
                  <Route path='segmentos' element={<CredTAESegmentsScreen />} />
                  <Route path="productos" >
                    <Route index element={<CredTAEProductsScreen />} />
                    <Route path="editar" element={<EditCredTAEProductScreen />} />
                  </Route>
                </Route>
                <Route path="pds">
                  <Route index element={<CredPDSScreen />} />
                  <Route path="segmentos" element={<CredPDSSegmentScreen />} />
                  <Route path="productos" >
                    <Route index element={<CredPDSProductScreen />} />
                    <Route path="editar" element={<EditCredPDSProductScreen />} />
                  </Route>
                </Route>
                <Route path="pin">
                  <Route index element={<CredPINScreen />} />
                  <Route path="segmentos" element={<CredPINSegmentScreen />} />
                  <Route path="productos" >
                    <Route index element={<CredPINProductScreen />} />
                    <Route path="editar" element={<EditCredPINProductScreen />} />
                  </Route>
                </Route>
              </Route>
              <Route path='amounts'>
                <Route index element={<AmountsScreen />} />
                <Route path='create' element={<CreateAmountScreen />} />
                <Route path='edit' element={<EditAmountScreen />} />
              </Route>
            </Route>
            <Route path='errorcodes'>
                <Route index element={<ErrorCodesScreen />} />
                <Route path='create' element={<CreateErrorCodesScreen />} />
                <Route path='edit' element={<EditErrorCodesScreen />} />
              </Route>
            <Route path="conciliaciones" >
              <Route index element={<ConciliacionScreen />} />
              <Route path='detail' element={<ConciliacionDetailScreen />} />
              <Route path='history' element={<ConciliacionHistoryScreen />} />
            </Route>
            <Route path='hebecodes'>
              <Route index element={<HebeCodesScreen />} />
              <Route path='create' element={<HebeCodesCreateScreen />} />
              <Route path='edit' element={<HebeCodesEditScreen />} />
            </Route>
          </Route>
          <Route path='conectores' >
            <Route index element={<ConnectorScreen />} />
            <Route path='crear' element={<CreateConnectorScreen />} />
            <Route path='editar' element={<EditConnectorScreen />} />
          </Route>
          <Route path='alertas' element={<AlertTab />} >
            <Route path='eventos'>
              <Route index element={<AlertMonitorScreen />} />
              <Route path='transacciones' element={<AlertTxnsMonitorScreen />} />
            </Route>
            <Route path='configuracion'>
              <Route index element={<AlertsScreen />} />
              <Route path='crear' element={<CreateAlertScreen />} />
              <Route path='editar' element={<EditAlertScreen />} />
            </Route>
          </Route>
          <Route path='servicios' >
            <Route path='monitor' element={<ServiceMonitorScreen />} />
            <Route path='historial' element={<ServiceMonitorHistorialScreen />} />
          </Route>
        </Route>
        <Route path='ruteo'>
          <Route path='simulador' element={<SimulatorScreen />} />
          <Route path="ladas">
            <Route index element={<LadasScreen />} />
            <Route path='crear' element={<CreateLadaScreen />} />
            <Route path='editar' element={<EditLadaScreen />} />
          </Route>
          <Route path="panel" element={<RouteTabScreen />}>
            <Route path="tae" element={<TAETabScreen />} >
              <Route index element={<TAERouteScreen />} />
              <Route path='excepciones'>
                <Route index element={<ExceptionScreen />} />
                <Route path='crear' element={<CreateExceptionScreen />} />
                <Route path='editar' element={<EditExceptionScreen />} />
              </Route>
            </Route>
            <Route path="pds" element={<PDSRouteScreen />} />
            <Route path="pin" element={<PINRouteScreen />} />
          </Route>
        </Route>
        <Route path='seguridad'>
          <Route path="usuarios" >
            <Route index element={<UserScreen />} />
            <Route path='crear' element={<CreateUserScreen />} />
            <Route path='editar' element={<EditUserScreen />} />
          </Route>
          <Route path="administradores" >
            <Route index element={<AdminScreen />} />
            <Route path='crear' element={<CreateUserScreen type="admin" />} />
            <Route path='editar' element={<EditUserScreen type="admin" />} />
          </Route>
          <Route path="roles" >
            <Route index element={<RoleScreen />} />
            <Route path='crear' element={<CreateRoleScreen />} />
            <Route path='editar' element={<EditRoleScreen />} />
          </Route>
          <Route path="permisos" >
            <Route index element={<PermissionScreen />} />
            <Route path='crear' element={<CreatePermissionScreen />} />
            <Route path='editar' element={<EditPermissionScreen />} />
          </Route>
        </Route>
        <Route path="cliente">
          <Route path="comercios" >
            <Route index element={<CommerceScreen />} />
            <Route path='crear' element={<CreateCommerceScreen />} />
            <Route path='editar' element={<EditCommerceScreen />} />
          </Route>
          <Route path="bolsas" >
            <Route index element={<BagScreen />} />
            <Route path='crear' element={<CreateBagScreen />} />
            <Route path='editar' element={<EditBagScreen />} />
            <Route path='operaciones' element={<BagOperationsScreen />} />
            <Route path='productos' element={<BagProductTabScreen />}>
              <Route path="tae">
                <Route index element={<BagTAEScreen />} />
                <Route path='operadoras' element={<BagTAEOperatorScreen />} />
                <Route path='regiones' element={<BagTAERegionScreen />} />
                <Route path='segmentos' element={<BagTAESegmentScreen />} />
                <Route path='denominaciones' element={<BagTAEDenominationScreen />} />
              </Route>
              <Route path="pds">
                <Route index element={<BagPDSScreen />} />
                <Route path="segmentos" element={<BagPDSSegmentScreen />} />
                <Route path="productos" element={<BagPDSProductScreen />} />
              </Route>
              <Route path="pin">
                <Route index element={<BagPINScreen />} />
                <Route path="segmentos" element={<BagPINSegmentScreen />} />
                <Route path="productos" element={<BagPINProductScreen />} />
              </Route>
            </Route>
          </Route>
          <Route path="puntos_de_venta" >
            <Route index element={<SalePointScreen />} />
            <Route path='crear' element={<CreateSalePointScreen />} />
            <Route path='editar' element={<EditSalePointScreen />} />
          </Route>
          <Route path="grupos" >
            <Route index element={<GroupScreen />} />
            <Route path='crear' element={<CreateGroupScreen />} />
            <Route path='editar' element={<EditGroupScreen />} />
          </Route>
        </Route>
        <Route path='venta' element={<SaleTabScreen />}>
          <Route path="tiempo-aire" element={<AirTimeSaleScreen />} />
          <Route path="servicios" element={<ServicePaymentSaleScreen />} />
          <Route path="pines-electronicos" element={<ElectronicPinSaleScreen />} />
        </Route>
        <Route path="reportes">
          <Route path="detallado" element={<DetailedScreen />} />
          <Route path="saldos" element={<BalancesScreen />} />
          <Route path="bitacora" element={<BinnacleScreen />} />
          <Route path="totales" element={<TotalReportScreen />} />
          <Route path="saldo_diario" element={<DailyTotalReportScreen />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes >
  )
}

const Redirect = ({ path }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(path)
    // eslint-disable-next-line
  }, [path])
}

export default RootRoutes;