import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from "moment";

import { AddCircleOutline, ErrorOutline, ChevronLeft, CompareArrows, Close, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { validatePermission } from 'helpers/authentication';
import { errorAlert, confirmAlert } from 'helpers/alerts';
import { CustomButton, FilterBar, TableData } from "components";

import { setErrorCodesProvider, setErrorCodesHistoryProvider, setProviderErrorCodeToCreate, setErrorCodesHistoryIdsProvider, setErrorCodeSelected } from 'redux/slices/admin/providerSlice';
import { getProviderCredsErrorCodesService, getProviderCredsErrorCodesHistoryService, deleteProviderCredsErrorCodesService } from 'services/admin/providerService';

function ErrorCodesScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const { current, errorCodesProvider, hebeErrorCodesHistoryList } = useSelector(store => store.providers)
  const errorCodesList = errorCodesProvider.map((e) => e.externalExceptionCode);

  useEffect(() => {
    dispatch(setTitle('Homologación Errores Proveedor'));
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getProviderCredsErrorCodesService(current.host)
      .then(response => dispatch(setErrorCodesProvider(response)))
      .catch(error => errorAlert(error));
  }, [update]);

  return (
    <>
      <h2 style={{ marginBottom: -45 }}>Códigos homologados</h2>
      <TableData
        dataRows={errorCodesProvider}
        headerButtons={[
          <CustomButton
            icon={<ChevronLeft fontSize="large" />}
            key="addProviderCred"
            name={"Volver"}
            click={() => navigate(-1)}
          />,
          validatePermission('proveedores', 'proveedores', ['agregar']) &&
          <CustomButton
            icon={<AddCircleOutline fontSize="large" />}
            key="addProviderCred"
            name={"Nueva Homologaciónz"}
            click={() => navigate("create")}
          />,
        ]}
        dataTitle={[
          "Proveedor",
          "HEBE Code",
          "Proveedor Code"
        ]}
        formatter={({ hebeExceptionCode, externalExceptionCode, connectorName }) => ([
          connectorName,
          hebeExceptionCode,
          externalExceptionCode
        ])}
        actions={[
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <Edit fontSize="medium" />,
            onClick: (data) => {
              dispatch(setErrorCodeSelected(data));
              navigate("edit");
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Eliminar",
            icon: <Close fontSize="medium" />,
            onClick: ({ sku, id }) => {
              confirmAlert({
                title: "Estás seguro que deseas eliminar este error code?",
                text: `Error Code: ${sku} será eliminada.`,
                onConfirm: async () => {
                  deleteProviderCredsErrorCodesService(id)
                    .then(() => setUpdate(update + 1))
                    .catch(error => errorAlert(error));
                }
              })
            },
          },
        ]}
      />
      <h2 style={{ marginBottom: 20 }}>Códigos recibidos</h2>
      <FilterBar
        handlerFilter={async (data) => {
          getProviderCredsErrorCodesHistoryService(current.host, data)
            .then(response => dispatch(setErrorCodesHistoryProvider(response)))
            .catch(error => errorAlert(error));
        }}
        defaultValues={{
          date: [moment().subtract(30, 'days').toDate(), new Date()],
        }}
        allValue={null}
        filters={[
          {
            type: "dates",
            name: "date",
            label: "Fecha",
          },
        ]}
      />
      <TableData
        dataRows={hebeErrorCodesHistoryList}
        headerButtons={[]}
        dataTitle={[
          "Homologado",
          "Error Code",
          "Message",
          "Count"
        ]}
        formatter={({ code, message, count }) => ([
          (errorCodesList.indexOf(code) > -1 ? "SI" : "NO"),
          code,
          message,
          count
        ])}
        actions={[
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Homologar",
            icon: <ErrorOutline fontSize="medium" />,
            validate: (data) => {
              return errorCodesList.indexOf(data.code) == -1
            },
            onClick: (data) => {
              dispatch(setProviderErrorCodeToCreate(data.code));
              navigate("create");
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Transacciones",
            icon: <CompareArrows fontSize="medium" />,
            validate: (data) => {
              return data.ids.length > 0
            },
            onClick: (data) => {
              dispatch(setErrorCodesHistoryIdsProvider(data.ids));
              navigate("/panel/transacciones");
            },
          },

        ]}
      />
    </>
  );
}


export default ErrorCodesScreen;